import { ReactNode, useMemo } from "react";
import clsx from "clsx";

import { Header } from "@/component/header";

import style from "./index.module.scss";

type Props = {
  children?: ReactNode;
  className?: string;
};
export function Layout(props: Props): JSX.Element {
  const { children, className } = props;
  const classNames = useMemo(() => clsx(style.main, className), [className]);
  return (
    <div className={style.layout}>
      <Header />
      <main className={classNames}>{children}</main>
    </div>
  );
}

export default Layout;
