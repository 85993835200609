import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { useSiteMetadata } from "@/hook";

import style from "./index.module.scss";

export function Header(): JSX.Element {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          relativePath: { eq: "banner.png" }
          sourceInstanceName: { eq: "image" }
        ) {
          childImageSharp {
            fluid(
              jpegProgressive: true
              maxWidth: 1200
              quality: 90
              srcSetBreakpoints: [240, 360, 480, 600, 720, 840, 960, 1080]
            ) {
              src
              aspectRatio
              base64
              srcSet
              sizes
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    `
  );
  const { fluid } = file.childImageSharp;
  const { title } = useSiteMetadata();
  return (
    <header className={style.header}>
      <Link to="/">
        <Img fluid={fluid} title={title} />
      </Link>
    </header>
  );
}
